import { sizeOf } from '../functions/size.factory';
import { filtered } from '../functions/filter.factory';
import { ids } from '../functions/ids.factory';
import { matchesRegex } from '../functions/regex.factory';
import { not } from '../functions/not.factory';
import { replaced } from '../functions/replace.factory';
import { multiply } from '../functions/multiply.factory';
import { quotient } from '../functions/division.factory';
import { firstTruthyIn } from '../functions/coalesce.factory';
import { propertyOf } from '../functions/get.factory';
import { translations } from '../functions/translations.factory';
import { concatenated } from '../functions/concat.factory';
import { outputs } from '../functions/outputs.factory';
import { includedIn } from '../functions/includes.factory';
import { distinctUntilChanged } from '../functions/distinct-until-changed.factory';
import { global } from '../functions/global.factory';
import { context } from '../functions/context.factory';
import { localWidgetId } from '../functions/local-widget-id.factory';
import { mapped } from '../functions/map.factory';
import { inverted } from '../functions/invert.factory';
import { aliases } from '../functions/aliases.factory';
import { valuesOf } from '../functions/values.factory';
export var idsMatching = function (pattern) {
  return filtered(ids(), function (id) {
    return matchesRegex(id, pattern);
  });
};
export var isEmpty = function () {
  var _a = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    _a[_i] = arguments[_i];
  }
  var value = _a[0];
  return not(sizeOf(value));
};
export var regexFromSting = function (string) {
  return replaced(string, /[-[/\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};
export var percentage = function (dividend, divisor) {
  return multiply([quotient(dividend, divisor), 100]);
};
export var translate = function (key) {
  return firstTruthyIn([propertyOf(translations(), key), concatenated(['_', key])]);
};
/**
 * Reduces the given widget ids to a mapped of outputs.
 *
 * [[WidgetInputModel.alias]] is used for the key if defined, otherwise it falls back to [[WidgetInputModel.id]].
 *
 * @returns A mapped of outputs
 */
export var outputMap = function (widgetIds) {
  return filtered(outputs(), function (item) {
    return includedIn(widgetIds, propertyOf(item, 'key'));
  });
};
export var outputMapMatching = function (widgetIdRegex) {
  return filtered(outputs(), function (item) {
    return matchesRegex(propertyOf(item, 'key'), widgetIdRegex);
  });
};
export var globalProperty = function (path) {
  return distinctUntilChanged(propertyOf(global(), path));
};
/**
 * @deprecated This is not type-safe. Use {@link propertyOf} in conjunction with {@link context} directly
 */
export var contextProperty = function (path) {
  return distinctUntilChanged(propertyOf(context(), path));
};
/**
 * @deprecated This is not truly type-safe. Use {@link propertyOf} in conjunction with {@link outputs} directly
 */
export var outputOf = function (widgetId) {
  return propertyOf(outputs(), widgetId);
};
/**
 * @deprecated This is not truly type-safe. Use {@link propertyOf} in conjunction with {@link outputs} directly
 */
export var outputPropertyOf = function (widgetId, path) {
  return distinctUntilChanged(propertyOf(outputOf(widgetId), path));
};
/**
 * @deprecated This is not truly type-safe. Use {@link propertyOf} in conjunction with {@link outputs} and {@link localWidgetId} directly
 */
export var outputProperty = function (path) {
  return outputPropertyOf(localWidgetId(), path);
};
/**
 * @deprecated This is not truly type-safe. Use {@link mapped} in conjunction with {@link outputMap} and {@link propertyOf} directly
 */
export var outputPropertyMap = function (widgetIds, path) {
  return mapped(outputMap(widgetIds), function (item) {
    return propertyOf(propertyOf(item, 'value'), path);
  });
};
/**
 * @deprecated This is not type-safe. Use {@link mapped} in conjunction with {@link outputMapMatching} and {@link propertyOf} directly
 */
export var outputPropertyMapMatching = function (widgetIdRegex, path) {
  return mapped(outputMapMatching(widgetIdRegex), function (item) {
    return propertyOf(propertyOf(item, 'value'), path);
  });
};
/**
 * @deprecated This is not type-safe.
 */
export var aliasOutputPropertyMapMatching = function (widgetIdRegex, path) {
  return mapped(filtered(inverted(aliases()), function (item) {
    return matchesRegex(propertyOf(item, 'value'), widgetIdRegex);
  }), function (item) {
    return outputPropertyOf(propertyOf(item, 'value'), path);
  });
};
/**
 * @deprecated This is not type-safe.
 */
export var outputPropertyList = function (widgetIds, path) {
  return valuesOf(outputPropertyMap(widgetIds, path));
};
/**
 * @deprecated This is not type-safe.
 */
export var outputPropertyListMatching = function (widgetIdRegex, path) {
  return valuesOf(outputPropertyMapMatching(widgetIdRegex, path));
};
