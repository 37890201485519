import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { FragmentInput } from '@trackback/widgets';

@Component({
  selector: 'tb-fragment',
  standalone: true,
  imports: [DynamicWidgetDirective],
  template: `
    @for (child of input.children; track $index) {
      <ng-container [dynamicWidget]="child" [context]="context" />
    }
  `,
  styles: `
    tb-fragment {
      display: contents;
    }
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class FragmentComponent {
  @Input()
  input: FragmentInput;

  @Input()
  context: Record<string, never>;
}
